import { ReactText, useRef, useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useField } from 'formik';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import useI18nError from 'new/i18n/useI18nError';

type Props = TextFieldProps & {
  name: string;
  isLoading?: boolean;
  options?: SelectOption[];
  isOpenByDefault?: boolean;
  menuItemStyles?: object;
  hiddenOptionsIds?: ReactText[];
};

export type SelectOption = {
  value: ReactText;
  label?: string;
  disabled?: boolean;
};

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isHidden',
})<{ isHidden?: boolean }>(({ isHidden }) => ({
  ...(isHidden
    ? {
        height: 0,
        overflow: 'hidden',
        padding: 0,
      }
    : {}),
}));

function Select<T>({
  name,
  isOpenByDefault,
  autoFocus,
  options = [],
  isLoading,
  children,
  menuItemStyles,
  hiddenOptionsIds,
  ...props
}: Props) {
  const [open, setOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>();
  const [field, meta] = useField<T>(name);
  const errorMessage = useI18nError(meta.error);
  const hasError = meta.touched && !!meta.error;
  useFieldAutofocus(inputRef, autoFocus);

  const getStartAdornment = () => {
    if (isLoading) {
      return (
        <InputAdornment position="start">
          <CircularProgress color="secondary" size={15} />
        </InputAdornment>
      );
    }
    return props.InputProps?.startAdornment;
  };

  useEffect(() => {
    if (isOpenByDefault) {
      setOpen(true);
    }
  }, []);

  const extraSelectProps = isOpenByDefault
    ? {
        open,
        onClose: () => setOpen(false),
        onOpen: () => setOpen(true),
      }
    : {};

  const selectProps = {
    ...extraSelectProps,
    ...props.SelectProps,
    IconComponent: ExpandMoreIcon,
  };

  return (
    <TextField
      select
      {...props}
      {...field}
      id={props.id || field.name}
      value={field.value || ''}
      variant={props.variant || 'standard'}
      error={hasError}
      helperText={hasError && errorMessage}
      sx={{ display: 'block' }}
      InputProps={{
        ...props.InputProps,
        startAdornment: getStartAdornment(),
      }}
      SelectProps={selectProps}
      inputRef={props.inputRef || inputRef}
    >
      {children
        ? children
        : options.map(({ value, label, disabled }) => (
            <StyledMenuItem
              key={value}
              value={value}
              disabled={disabled}
              sx={menuItemStyles}
              isHidden={hiddenOptionsIds?.includes(value)}
            >
              {label || value}
            </StyledMenuItem>
          ))}
    </TextField>
  );
}

export default Select;
