import React from 'react';
import { Link } from 'react-router';
import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocationDescriptor } from 'history';
import IconFontAwesome, {
  IconFontAwesomeProps,
} from 'new/components/IconFontAwesome';

const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
  '&:hover': {
    svg: {
      color: theme.palette.primary.main,
    },
  },
  width: 'fit-content',
  height: 'fit-content',

  a: {
    textDecoration: 'none',
    color: 'unset',
  },
}));

type Props = Partial<IconFontAwesomeProps> & {
  customIcon?: React.ReactNode;
  to?: LocationDescriptor;
  disabled?: boolean;
};

const IconButton: React.FC<Props> = ({
  customIcon,
  name,
  to,
  disabled,
  ...props
}) => {
  let icon;

  if (customIcon) {
    icon = customIcon;
  } else if (name) {
    icon = <IconFontAwesome name={name} {...props} />;
  }

  return (
    <StyledIconButton disabled={disabled}>
      {to ? <Link to={to}>{icon}</Link> : icon}
    </StyledIconButton>
  );
};

export default IconButton;
