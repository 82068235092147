import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Theme,
  styled,
} from '@mui/material';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { SxProps } from '@mui/system/styleFunctionSx';
import IconFontAwesome, { Name } from 'new/components/IconFontAwesome';
import { TENANT } from 'new/tenants';

type Props = Omit<ExtendButtonBase<ButtonTypeMap>, 'onClick'> & {
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
  isNewTab?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  form?: string;
  isTextInline?: boolean;
  sx?: SxProps<Theme>;
  icon?: Name;
  alignLeft?: boolean;
  asLink?: boolean;
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'alignLeft',
})<{ alignLeft: boolean }>(({ alignLeft }) => ({
  fontWeight: 400,
  minWidth: 'unset',
  width: 'fit-content',
  margin: 0,
  padding: 8,
  paddingLeft: alignLeft ? 0 : 8,
  textTransform: 'none',
  textDecoration: 'underline',

  '.MuiStack-root': {
    justifyContent: alignLeft ? 'flex-start' : 'center',
    '.MuiBox-root:first-of-type': alignLeft
      ? {
          margin: 0,
        }
      : {},
  },

  '&.isTextInline': {
    padding: 0,
    marginTop: -3,
    fontFamily: TENANT.theme.modal.fontFamily,
  },

  ':hover': {
    textDecoration: 'underline',
  },
}));

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'alignLeft',
})<{ alignLeft: boolean }>(({ theme, alignLeft }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  paddingLeft: alignLeft ? 0 : 8,
  '.MuiStack-root': {
    alignItems: alignLeft ? 'flex-start' : 'center',
    '.MuiBox-root:first-of-type': alignLeft
      ? {
          margin: 0,
        }
      : {},
  },

  ':hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

const LinkButton: React.FC<Props> = ({
  id,
  children,
  onClick,
  to,
  isNewTab,
  disabled,
  isLoading,
  type,
  form,
  isTextInline,
  icon,
  alignLeft = false,
  asLink = false,
  ...props
}) => {
  let iconComponent;

  if (icon) {
    if (isLoading) {
      iconComponent = <CircularProgress color="secondary" size={16} />;
    }

    iconComponent = <IconFontAwesome name={icon} size="1x" />;
  }

  const buttonContent = (
    <Stack direction="row" alignItems="center" whiteSpace="pre">
      <Box component="span" mr="5px">
        {iconComponent}
      </Box>
      {children}
    </Stack>
  );

  return to || asLink ? (
    <StyledLink
      id={id}
      to={to ?? ''}
      target={isNewTab ? '_blank' : undefined}
      onClick={onClick}
      alignLeft={alignLeft}
      {...props}
    >
      {buttonContent}
    </StyledLink>
  ) : (
    <StyledButton
      id={id}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      form={form}
      className={cx({ isTextInline })}
      alignLeft={alignLeft}
      {...props}
    >
      {buttonContent}
    </StyledButton>
  );
};

export default LinkButton;
