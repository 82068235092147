import { NamedRoute } from '../../routing/types';

export type SignupRouteNames = 'SignupMyHqPage';

export const signupRoutes: NamedRoute[] = [
  {
    name: 'SignupMyHqPage',
    path: '/signup/my-hq',
    permission: '_all',
  },
];
