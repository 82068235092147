import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  OfficeDiscountResponse,
  OfficeInfoNoAuth,
  OfficeInfoNoAuthReq,
  zipCodeResponse,
} from 'new/modules/ExternalPatientSignup/types';
import { LogInviteIdRequest } from '../Setup/types';

export const officeInfoNoAuth = createEndpoint<
  OfficeInfoNoAuth,
  OfficeInfoNoAuthReq
>({
  url: '/dentists/details/:id/no-auth',
  endpoint: ({ url, params }) =>
    api.get(
      `${url}?smBusinessInfoId=${params?.smBusinessInfoId}&token=${params?.token}`,
    ),
});

export const getOfficeDiscount = createEndpoint<
  OfficeDiscountResponse,
  { smBusinessInfoId: number | null; dentistInfoId: number }
>({
  url: '/dentists/get-office-discount',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getNearbyZipCode = createEndpoint<
  zipCodeResponse,
  { dentistInfoId: number }
>({
  url: `/dentists/nearby/`,
  endpoint: ({ url, params }) => api.get(url + params?.dentistInfoId),
});

export const sendInviteId = createEndpoint<unknown, LogInviteIdRequest>({
  url: '/send-invite/log-clicked-invite/',
  endpoint: ({ url, params }) => api.get(`${url}${params?.inviteId}`),
});
