import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import create from 'zustand';
import { Id } from 'new/api/types';
import { getFlattedPlans, getIsMonthlyPlan } from 'new/components/PlanSelect';
import useActiveOffice from 'new/hooks/useActiveOffice';
import useUserRole from 'new/hooks/useUserRole';
import { LocaleContext } from 'new/i18n/LocaleContext';
import { LogInviteIdRequest } from 'new/modules/Setup/types';
import {
  getOfficeDiscount,
  officeInfoNoAuth,
  sendInviteId,
  getNearbyZipCode,
} from 'new/modules/ExternalPatientSignup/endpoints';
import {
  ExternalPatientsStore,
  ExternalSignupCheckoutForm,
  SignupData,
} from 'new/modules/ExternalPatientSignup/types';
import {
  AddMember,
  AddMemberForm,
  PlanType,
  SignupCheckoutForm,
  PlansValues,
  AdditionalPlanObject,
} from 'new/modules/InternalPatientSignup/types';
import { formatNumberAsCurrency } from 'new/utils/numbers';
import { useInternalPatientsStore } from '../InternalPatientSignup/hooks';
import { GPP_PERCENTAGE_PRICING } from '../ManagePlans/constants';
import { useGetPlans } from '../MembershipPlansManage/hooks';
import { getGroupDiscount, getGroupDiscounts } from './utils';

export const useExternalPatientsStore = create<ExternalPatientsStore>(
  (set) => ({
    officeId: '',
    setOfficeId: (officeId) => set({ officeId }),
    appliedGroupCode: undefined,
    planType: undefined,
    members: [] as AddMember[],
    setMembers: (members) => set({ members }),
    setAccountOwner: (accountOwner) =>
      set({ accountOwner: accountOwner || undefined }),
    setGroupCode: (appliedGroupCode) => set({ appliedGroupCode }),
    setPlanType: (planType: PlanType) => set({ planType }),
    resetPatientStore: () =>
      set({
        members: [],
        officeId: '',
        accountOwner: undefined,
        appliedGroupCode: undefined,
      }),
  }),
);

function isInternalAccountOwner(
  accountOwner?: SignupCheckoutForm | ExternalSignupCheckoutForm,
): accountOwner is SignupCheckoutForm {
  return (
    !!accountOwner &&
    !!Object.prototype.hasOwnProperty.call(accountOwner, 'additionalMembers')
  );
}

const usePatientSignupStore = (type: 'internal' | 'external') => {
  const externalStore = useExternalPatientsStore();
  const internalStore = useInternalPatientsStore();

  if (type === 'internal') return internalStore;
  return externalStore;
};

export const useOfficeInfoNoAuth = (
  officeId?: Id,
  smBusinessInfoId?: string,
  token?: string,
) =>
  useQuery({
    queryKey: ['office query no auth', officeId],
    queryFn: () =>
      officeInfoNoAuth
        .endpoint({ smBusinessInfoId, token }, officeId)
        .then((res) => res.data),
    enabled: !!officeId,
  });

const useGetOfficeDiscount = (dentistInfoId?: string) => {
  return useQuery({
    queryKey: ['OFFICE_DISCOUNT', dentistInfoId],
    queryFn: () =>
      getOfficeDiscount
        .endpoint({
          dentistInfoId: Number(dentistInfoId),
          smBusinessInfoId: null,
        })
        .then((res) => res.data),
    enabled: !!dentistInfoId,
  });
};

export const useGetNearbyZipcode = (dentistInfoId?: string) =>
  useQuery({
    queryKey: ['NEARBY_ZIPCODE', dentistInfoId],
    queryFn: () =>
      getNearbyZipCode
        .endpoint({
          dentistInfoId: Number(dentistInfoId),
        })
        .then((res) => {
          return res.data.zipCodes;
        }),
    enabled: !!dentistInfoId,
  });

export const useSendInviteId = () =>
  useMutation<unknown, unknown, LogInviteIdRequest>((params) =>
    sendInviteId.endpoint(params),
  );

const setAdditionalPlansObject = (member: AddMemberForm) => {
  const plansOnlyObject = Object.keys(member).reduce<PlansValues>(
    (acc, key) => {
      if (
        key.includes('plan') &&
        !key.includes('planName') &&
        member[key as keyof AddMemberForm] !== null &&
        member[key as keyof AddMemberForm] !== ''
      ) {
        // @ts-ignore
        acc[key] = member[key];
      }
      return acc;
    },
    {},
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { plan, planName, ...extraPlansOnlyObject } = plansOnlyObject;
  return extraPlansOnlyObject;
};

export const useSignupData = (
  type: 'internal' | 'external',
  gppNeedsAccountOwnerAddress = false,
  existingMemberCount = 0,
  accountOwnerAddress?: string | null,
  monthFractionTillNextPayment?: number,
): SignupData => {
  const {
    members,
    setMembers,
    setGroupCode,
    officeId: storeOfficeId,
    appliedGroupCode,
    accountOwner,
    planType: selectedPlanType,
  } = usePatientSignupStore(type);
  const { activeOfficeId, legacyPricing } = useActiveOffice();
  const officeId = type == 'internal' ? activeOfficeId : storeOfficeId;
  const { data: officeDiscount, isLoading: isLoadingDiscounts } =
    useGetOfficeDiscount(officeId);

  const { data: allPlans, isLoading: isLoadingPlans } = useGetPlans(officeId);
  const plans = getFlattedPlans(allPlans?.custom_memberships);
  const { isSuperAdmin } = useUserRole();
  const { locale } = useContext(LocaleContext);
  const isSpanish = locale === 'es-US';

  const { data: officeInfo, isLoading: isLoadingOffice } =
    useOfficeInfoNoAuth(officeId);
  const gpp = officeInfo?.data.dentistInfo.gpp;
  const isOfficeGppActive =
    gpp?.active &&
    gpp.approvalDate &&
    ((gppNeedsAccountOwnerAddress && !!accountOwnerAddress) ||
      !gppNeedsAccountOwnerAddress);

  const groupDiscountsData = getGroupDiscounts(officeInfo?.data);

  const totalMembersCount = useMemo(() => {
    let numberOfMembers = members.length + existingMemberCount;
    if (
      isInternalAccountOwner(accountOwner) &&
      accountOwner?.additionalMembers &&
      accountOwner?.additionalMembers.length > 0
    ) {
      if (
        accountOwner?.status &&
        ['active', 'active_transferred', 'active_cash', 'past_due'].indexOf(
          accountOwner?.status,
        ) != -1
      )
        numberOfMembers++;
      accountOwner?.additionalMembers?.map((additionalMember) => {
        if (
          additionalMember.statuses.some((status) =>
            ['active', 'active_transferred', 'active_cash'].includes(status),
          )
        )
          numberOfMembers++;
      });
    }
    return numberOfMembers;
  }, [members]);

  const groupDiscountPercentForAddingMember = useMemo(
    () =>
      getGroupDiscount({
        groupDiscounts: groupDiscountsData,
        numberOfMembers: totalMembersCount,
      }),
    [JSON.stringify(groupDiscountsData), totalMembersCount, members],
  );

  const groupDiscountPercentForAddingPlan = getGroupDiscount({
    groupDiscounts: groupDiscountsData,
    numberOfMembers: totalMembersCount - 1,
  });

  const codeDiscountPercent = appliedGroupCode?.discountByDentist ?? 0;

  const setActiveFee = ({
    isMonthly,
    isPlanGppActive,
    payor,
    activationFee,
  }: Pick<AddMember, 'isMonthly' | 'isPlanGppActive' | 'payor'> & {
    activationFee: number;
  }) => {
    return isMonthly && isPlanGppActive && payor === 'member'
      ? 'setup'
      : isMonthly && !isPlanGppActive && !!activationFee
      ? 'activation'
      : null;
  };

  const getNumericPrice = (price?: string | null) =>
    price ? Number(price) : 0;

  const setDiscountObject = ({
    disableFamilyDiscounts,
    numericPrice,
  }: {
    disableFamilyDiscounts?: boolean;
    numericPrice: number;
  }) => {
    const calcDiscount = (isAddingMember: boolean) => {
      const setGroupDiscountPercent = isAddingMember
        ? groupDiscountPercentForAddingMember
        : groupDiscountPercentForAddingPlan;

      const perPlanGroupDiscountPercent = disableFamilyDiscounts
        ? 0
        : setGroupDiscountPercent;

      const isGroupDiscount = perPlanGroupDiscountPercent > codeDiscountPercent;

      const discountPercent =
        perPlanGroupDiscountPercent > codeDiscountPercent
          ? perPlanGroupDiscountPercent
          : codeDiscountPercent;

      const discountedPrice =
        numericPrice - (numericPrice * discountPercent) / 100;

      const gppActivationFee =
        discountedPrice * 12 * (GPP_PERCENTAGE_PRICING / 100);

      return {
        isGroupDiscount,
        discountedPrice,
        discountPercent,
        gppActivationFee,
      };
    };

    const {
      isGroupDiscount,
      discountedPrice: discountedPriceForAddingMember,
      discountPercent: discountPercentForAddingMember,
      gppActivationFee,
    } = calcDiscount(true);
    const {
      discountedPrice: discountedPriceForAddingPlan,
      discountPercent: discountPercentForAddingPlan,
    } = calcDiscount(false);

    return {
      isGroupDiscount,
      discountedPriceForAddingMember,
      discountPercentForAddingMember,
      discountedPriceForAddingPlan,
      discountPercentForAddingPlan,
      gppActivationFee,
    };
  };

  const getPlanDetails = (planId: string, checkPlanType: boolean) => {
    const {
      name,
      spanishName,
      price,
      type: planType,
      insured,
      uninsured,
      disableGpp,
      activationFee: activationFeeAmount,
      disableFamilyDiscounts,
    } = plans.find((item) => item.id === Number(planId)) || {};

    const numericPrice = getNumericPrice(price);
    const isMonthly = getIsMonthlyPlan(planType);

    const {
      isGroupDiscount,
      discountedPriceForAddingMember,
      discountPercentForAddingMember,
      discountedPriceForAddingPlan,
      discountPercentForAddingPlan,
      gppActivationFee,
    } = setDiscountObject({ disableFamilyDiscounts, numericPrice });

    let hasCorrectPlanType = true;
    if (checkPlanType)
      hasCorrectPlanType =
        (selectedPlanType == 'insured' && !!insured) ||
        (selectedPlanType == 'uninsured' && !!uninsured);

    const isPlanGppActive = !!isOfficeGppActive && !disableGpp;

    const activationFeeNumber = activationFeeAmount || 0;

    const setPriceObject = (numericPrice: number) => ({
      value: numericPrice,
      currency: formatNumberAsCurrency(numericPrice, 2, true),
    });

    const setDiscountedPriceObjectForAddingMember = () => ({
      value: discountedPriceForAddingMember,
      currency: formatNumberAsCurrency(discountedPriceForAddingMember, 2, true),
      isGroupDiscount,
      percent: discountPercentForAddingMember,
    });

    const setDiscountedPriceObjectForAddingPlan = () => ({
      value: discountedPriceForAddingPlan,
      currency: formatNumberAsCurrency(discountedPriceForAddingPlan, 2, true),
      isGroupDiscount,
      percent: discountPercentForAddingPlan,
    });

    const activationFeeObject = {
      value: activationFeeNumber,
      currency: formatNumberAsCurrency(activationFeeNumber, 2, true),
    };

    const setupFee = {
      value: gppActivationFee,
      currency: formatNumberAsCurrency(gppActivationFee, 2, true),
    };

    return {
      numericPrice,
      isMonthly,
      hasCorrectPlanType,
      isPlanGppActive,
      activationFee: activationFeeObject,
      setupFee,
      name,
      spanishName,
      setActiveFee,
      setPriceObject,
      setDiscountedPriceObjectForAddingMember,
      setDiscountedPriceObjectForAddingPlan,
    };
  };

  const setMembersFromForm = useCallback(
    (formMembers: AddMemberForm[], checkPlanType = false) => {
      setMembers(
        formMembers.map((member) => {
          const setAdditionalPlans = () => {
            const additionalPlans = member.additionalPlans;
            const isAtLeastOnePlanId =
              additionalPlans?.length &&
              typeof additionalPlans?.[0] === 'number';

            if (isAtLeastOnePlanId) {
              return additionalPlans?.map((el) => ({ plan: el }));
            }

            const additionalPlansOnlyObject = setAdditionalPlansObject(member);
            // @ts-ignore
            const additionalPlansToArray = Object.values(
              additionalPlansOnlyObject,
            ).reduce<{ plan: number }[]>(
              // @ts-ignore
              (acc, value) => {
                // @ts-ignore
                return (acc = [...acc, { plan: value }]);
              },
              [],
            );

            const areAdditionalPlansUpdated =
              JSON.stringify(additionalPlans) !==
              JSON.stringify(additionalPlansToArray);

            if (areAdditionalPlansUpdated) {
              // @ts-ignore
              return additionalPlansToArray?.map((el) => {
                const {
                  numericPrice,
                  isMonthly,
                  isPlanGppActive,
                  name,
                  setPriceObject,
                  setDiscountedPriceObjectForAddingMember,
                  activationFee,
                  setupFee,
                } = getPlanDetails(el.plan, checkPlanType);

                return {
                  plan: el.plan,
                  planName: name,
                  price: setPriceObject(numericPrice),
                  discountedPrice: setDiscountedPriceObjectForAddingMember(),
                  discountedPriceForAddingPlan:
                    setDiscountedPriceObjectForAddingPlan(),
                  isMonthly,
                  isPlanGppActive,
                  waiveFee: member.waiveFee,
                  allowPayorSelection:
                    !legacyPricing &&
                    type === 'internal' &&
                    isMonthly &&
                    isPlanGppActive &&
                    ((isSuperAdmin && gpp?.gppAdminOnlyWaive == true) ||
                      gpp?.gppAdminOnlyWaive == false),
                  allowWaiveFee:
                    type === 'internal' &&
                    isMonthly &&
                    !isPlanGppActive &&
                    !!activationFee,
                  activeFee: setActiveFee({
                    isMonthly,
                    isPlanGppActive,
                    payor: member.payor,
                    activationFee: activationFee.value,
                  }),
                  activationFee,
                  setupFee,
                  payor: member.payor,
                };
              });
            }

            return additionalPlans;
          };

          const {
            numericPrice,
            isMonthly,
            hasCorrectPlanType,
            isPlanGppActive,
            activationFee,
            setupFee,
            name,
            spanishName,
            setActiveFee,
            setPriceObject,
            setDiscountedPriceObjectForAddingMember,
            setDiscountedPriceObjectForAddingPlan,
          } = getPlanDetails(member.plan, checkPlanType);

          return {
            ...member,
            plan: hasCorrectPlanType ? member.plan : '',
            planName: (isSpanish && spanishName ? spanishName : name) || '',

            additionalPlans: setAdditionalPlans(),

            isEdit: hasCorrectPlanType ? member.isEdit : true,
            price: setPriceObject(numericPrice),
            discountedPrice: setDiscountedPriceObjectForAddingMember(),
            discountedPriceForAddingPlan:
              setDiscountedPriceObjectForAddingPlan(),
            activationFee,
            setupFee,
            allowPayorSelection:
              !legacyPricing &&
              type === 'internal' &&
              isMonthly &&
              isPlanGppActive &&
              ((isSuperAdmin && gpp?.gppAdminOnlyWaive == true) ||
                gpp?.gppAdminOnlyWaive == false),
            allowWaiveFee:
              type === 'internal' &&
              isMonthly &&
              !isPlanGppActive &&
              !!activationFee,
            activeFee: setActiveFee({
              isMonthly,
              isPlanGppActive,
              payor: member.payor,
              activationFee: activationFee.value,
            }),
            isMonthly,
            isPlanGppActive,
          };
        }),
      );
    },
    [
      groupDiscountPercentForAddingMember,
      codeDiscountPercent,
      JSON.stringify(plans),
      isOfficeGppActive,
      isSpanish,
      isSuperAdmin,
      selectedPlanType,
    ],
  );

  const membersForForm = useMemo(
    () =>
      members.map((member) => {
        const extraPlansObject = setAdditionalPlansObject(member);

        return {
          firstName: member.firstName,
          lastName: member.lastName,
          dob: member.dob,
          plan: member.plan,
          ...extraPlansObject,
          additionalPlans:
            member.additionalPlans ??
            Object.values(extraPlansObject).filter((el) => el),

          waiveFee: member.waiveFee,
          payor: member.payor,
          isEdit: member.isEdit,
          chartId: member.chartId,
          id: member.id,
          patientSignupUpdatedFees: member.patientSignupUpdatedFees,
        };
      }),
    [JSON.stringify(members)],
  );

  useEffect(() => {
    setMembersFromForm(membersForForm);
  }, [setMembersFromForm, JSON.stringify(membersForForm)]);

  const calcTotal = (
    shouldIncludeYearlyCostOfMonthlyPayment: boolean,
    isAddingMember: boolean,
  ) =>
    members.reduce((acc, member) => {
      const {
        price: memberPrice,
        discountedPrice: discountedPriceForAddingMember,
        discountedPriceForAddingPlan,
        isMonthly,
        activeFee,
        waiveFee,
        activationFee,
        setupFee,
        additionalPlans,
      } = member;

      const memberDiscountedPrice = isAddingMember
        ? discountedPriceForAddingMember
        : discountedPriceForAddingPlan;
      const fractionFactor =
        isMonthly && monthFractionTillNextPayment
          ? monthFractionTillNextPayment
          : 1;

      const discountedPriceValue = memberDiscountedPrice.value * fractionFactor;
      const discountedPrice = {
        ...memberDiscountedPrice,
        value: discountedPriceValue,
        currency: formatNumberAsCurrency(discountedPriceValue, 2, true),
      };

      const calcPrice = ({
        price,
        discountedPrice,
        isMonthly,
      }: Pick<AddMember, 'price' | 'discountedPrice' | 'isMonthly'>) => {
        if (price.value > discountedPrice.value) {
          return discountedPrice.value;
        }

        return (
          price.value *
          (isMonthly && shouldIncludeYearlyCostOfMonthlyPayment ? 12 : 1)
        );
      };

      const calcFee = ({
        activeFee,
        waiveFee,
        activationFee,
        setupFee,
      }: Pick<
        AddMember,
        'activeFee' | 'waiveFee' | 'activationFee' | 'setupFee'
      >) => {
        if (activeFee === 'activation' && !waiveFee) {
          return activationFee.value;
        }

        if (activeFee === 'setup') {
          return setupFee.value;
        }

        return 0;
      };

      const priceValue = memberPrice.value * fractionFactor;
      const price = {
        value: priceValue,
        currency: formatNumberAsCurrency(priceValue, 2, true),
      };

      const planPrice =
        calcPrice({ price, discountedPrice, isMonthly }) +
        calcFee({ activeFee, waiveFee, activationFee, setupFee });

      const additionalPlansPrice =
        (additionalPlans as unknown as AdditionalPlanObject[])?.reduce(
          (acc, plan) => {
            const {
              price,
              discountedPrice,
              isMonthly,
              activeFee,
              waiveFee,
              activationFee,
              setupFee,
            } = plan;

            const planPrice =
              calcPrice({ price, discountedPrice, isMonthly }) +
              calcFee({ activeFee, waiveFee, activationFee, setupFee });

            acc = acc + planPrice;

            return acc;
          },
          0,
        ) ?? 0;

      acc = acc + planPrice + additionalPlansPrice;

      return acc;
    }, 0);

  const calcTotalAmount = (
    shouldIncludeYearlyCostOfMonthlyPayment: boolean,
    isAddingMember: boolean,
  ) => {
    const value = calcTotal(
      shouldIncludeYearlyCostOfMonthlyPayment,
      isAddingMember,
    );

    return {
      value,
      currency: formatNumberAsCurrency(value, 2, true),
    };
  };

  const calcTotalAmountForMultipleMembersAndPlans = () => {
    const total = members.reduce((acc, member) => {
      const {
        plan: planId,
        price,
        discountedPrice,
        isMonthly,
        activeFee,
        waiveFee,
        activationFee,
        setupFee,
        additionalPlans,
        patientSignupUpdatedFees,
      } = member;

      const calcPrice = ({
        price,
        discountedPrice,
      }: Pick<AddMember, 'price' | 'discountedPrice' | 'isMonthly'>) => {
        if (price.value > discountedPrice.value) {
          return discountedPrice.value;
        }

        return price.value;
      };

      const calcFee = ({
        activeFee,
        waiveFee,
        activationFee,
        setupFee,
        planId,
      }: Pick<
        AddMember,
        'activeFee' | 'waiveFee' | 'activationFee' | 'setupFee'
      > & { planId?: string }) => {
        if (activeFee === 'activation' && !waiveFee) {
          const isWaiveFee = patientSignupUpdatedFees?.['waiveFee' + planId];

          return isWaiveFee ? 0 : activationFee.value;
        }

        if (activeFee === 'setup') {
          const isOfficePays =
            patientSignupUpdatedFees?.['payor' + planId] === 'office';

          return isOfficePays ? 0 : setupFee.value;
        }

        return 0;
      };

      const planPrice =
        calcPrice({ price, discountedPrice, isMonthly }) +
        calcFee({ activeFee, waiveFee, activationFee, setupFee, planId });

      const additionalPlansPrice =
        (additionalPlans as unknown as AdditionalPlanObject[])?.reduce(
          (acc, plan) => {
            const {
              plan: planId,
              price,
              discountedPrice,
              isMonthly,
              activeFee,
              waiveFee,
              activationFee,
              setupFee,
            } = plan;

            const planPrice =
              calcPrice({ price, discountedPrice, isMonthly }) +
              calcFee({ activeFee, waiveFee, activationFee, setupFee, planId });

            acc = acc + planPrice;

            return acc;
          },
          0,
        ) ?? 0;

      acc = acc + planPrice + additionalPlansPrice;

      return acc;
    }, 0);

    return {
      value: total,
      currency: formatNumberAsCurrency(total, 2, true),
    };
  };

  return {
    totalAmount: calcTotalAmount(false, true),
    totalAmountForAddingPlan: calcTotalAmount(false, false),
    totalCommitment: calcTotalAmount(true, true),
    totalCommitmentForAddingPlan: calcTotalAmount(true, false),
    totalAmountForMultipleMembersAndPlans:
      calcTotalAmountForMultipleMembersAndPlans(),
    members,
    codeDiscounts: officeDiscount?.discounts ?? [],
    setMembersFromForm,
    setMembers,
    membersForForm,
    gpp,
    setGroupCode,
    isLoading: isLoadingDiscounts || isLoadingPlans || isLoadingOffice,
    totalMembersCount,
    codeDiscountPercent,
  };
};
