import React, { ReactText, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  InputLabel,
  Tooltip,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Search from '@mui/icons-material/Search';
import useFieldAutofocus from 'new/hooks/useFieldAutofocus';
import IconFontAwesome from 'new/components/IconFontAwesome';
import useI18nError from 'new/i18n/useI18nError';
import LinkButton from 'new/components/LinkButton';

export type InputFieldProps = TextFieldProps & {
  name: string;
  fixedLabel?: React.ReactNode;
  isLoading?: boolean;
  isSearchInput?: boolean;
  tooltip?: string;
  nameMulti?: string;
  isStandardMulti?: boolean;
};

type StyledTextFieldProps = {
  multiline?: boolean;
  label?: React.ReactNode;
  variant?: string;
  isErrorMoved?: boolean;
};

const StyledWrapper = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'multiline' &&
    prop !== 'label' &&
    prop !== 'variant' &&
    prop !== 'isErrorMoved',
})<StyledTextFieldProps>(
  ({ multiline, label, theme, variant, isErrorMoved }) => {
    const textareaStyles = multiline
      ? {
          paddingTop: 4,
          paddingBottom: 10,
          marginTop: label ? 18 : undefined,
        }
      : {};

    const labelStyles =
      multiline && label
        ? {
            '&.MuiInputLabel-shrink': {
              left: -12,
            },
          }
        : {};

    return {
      position: 'relative',

      '.MuiOutlinedInput-root': textareaStyles,

      '.MuiFormLabel-root': labelStyles,
      textarea: {
        position: 'relative',
        zIndex: 1,
      },

      fieldset: {
        backgroundColor: multiline ? theme.palette.grey[100] : 'auto',
        borderColor: multiline ? theme.palette.grey[300] : 'auto',
        legend: {
          display:
            (multiline && !label) || variant === 'outlined' ? 'none' : 'block',
        },
      },

      '.labelTooltip': {
        position: 'absolute',
        top: 8,
        right: -20,
        zIndex: 2,
      },

      '> div > p': {
        position: isErrorMoved ? 'absolute' : 'initial',
        top: -5,
        left: 122,
      },
    };
  },
);

const StyledLinkButton = styled(LinkButton)({
  position: 'absolute',
  top: -20,
  right: 0,
  height: 12,
});

const StyledSpanChipsWrapper = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: 54,
  zIndex: 2,
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  marginTop: 6,
  paddingLeft: 8,

  '> div': {
    height: 16,
    background: theme.palette.primary.main,
    marginBottom: '3px',
    marginLeft: '3px',

    '> span:first-of-type': {
      paddingLeft: '4px',
      paddingRight: theme.spacing(1),
    },

    'svg.MuiChip-deleteIcon': {
      fontSize: 16,
      marginRight: 0,
    },
  },
}));

function Input({
  name,
  fixedLabel,
  isLoading,
  isSearchInput,
  autoFocus,
  tooltip,
  nameMulti = '',
  isStandardMulti,
  ...props
}: InputFieldProps) {
  const inputRef = useRef<HTMLInputElement>();
  const [field, meta] = useField<ReactText>(name);
  const hasError = !!meta.error && meta.touched;
  const disabled = isLoading || props.disabled;
  const errorMessage = useI18nError(meta.error);
  const { setFieldValue, values: allValues } = useFormikContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const values = allValues as { [key: string]: any }; //TODO change or leave?

  useFieldAutofocus(inputRef, autoFocus);
  const getEndAdornment = () => {
    if (isLoading) {
      return (
        <InputAdornment position="end">
          <CircularProgress color="secondary" size={15} />
        </InputAdornment>
      );
    }
    if (isSearchInput) {
      return (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      );
    }
    return props.InputProps?.endAdornment;
  };

  let variant = props.variant || 'standard';

  if (props.multiline) {
    variant = 'outlined';
  }

  const deleteChipFromMulti = (chipName: string) =>
    setFieldValue(
      nameMulti,
      values[nameMulti].filter((name: string) => name !== chipName),
    );

  return (
    <>
      <StyledWrapper
        multiline={props.multiline}
        label={props.label}
        variant={variant}
        isErrorMoved={!!nameMulti && values[nameMulti]?.length > 0}
      >
        {fixedLabel && <InputLabel>{fixedLabel}</InputLabel>}
        {tooltip && (
          <Tooltip placement="top" title={tooltip}>
            <div className="labelTooltip">
              <IconFontAwesome name="faQuestion" color="grey400" isTooltip />
            </div>
          </Tooltip>
        )}
        <TextField
          {...props}
          {...field}
          id={props.id || field.name}
          onChange={(e) => {
            field.onChange(e);
            props.onChange?.(e);
          }}
          onBlur={(e) => {
            field.onBlur(e);
            props.onBlur?.(e);
          }}
          onKeyDown={
            nameMulti
              ? (e) => {
                  if (nameMulti && e.key === 'Enter') {
                    e.preventDefault();
                  }
                }
              : undefined
          }
          error={hasError}
          helperText={(hasError && errorMessage) || props.helperText}
          variant={isStandardMulti ? 'standard' : variant}
          InputProps={{
            endAdornment: getEndAdornment(),
            ...props.InputProps,
          }}
          disabled={disabled}
          inputRef={props.inputRef || inputRef}
        />
        {!!nameMulti && (
          <>
            <StyledSpanChipsWrapper>
              {values[nameMulti]?.map((chipName: string) => (
                <Chip
                  key={chipName}
                  label={chipName}
                  onDelete={() => deleteChipFromMulti(chipName)}
                  color="primary"
                />
              ))}
            </StyledSpanChipsWrapper>
            <StyledLinkButton
              onClick={() => {
                setFieldValue(field.name, '');
                setFieldValue(nameMulti, [
                  ...(values[nameMulti] ?? []),
                  field.value,
                ]);
                inputRef.current?.focus();
              }}
              disabled={
                field.value === '' || values[nameMulti]?.includes(field.value)
              }
            >
              Add more
            </StyledLinkButton>
          </>
        )}
      </StyledWrapper>
    </>
  );
}

export { Input };
