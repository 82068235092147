import Helmet from 'react-helmet';
import useActiveOffice from 'new/hooks/useActiveOffice';
import { PROXY_HOST, TENANT } from 'new/tenants';
import { staticPages } from 'common/constants';
import { useOfficeInfoNoAuth } from 'new/modules/ExternalPatientSignup/hooks';

const AppHelmet = () => {
  const pathname = window.location.href.replace(PROXY_HOST, '').toLowerCase();
  const signupPath = `accounts/signup/my-${TENANT.professionalName}/`;
  const dentistInfoId = parseInt(pathname.replace(signupPath, ''));
  const { data: officeInfo } = useOfficeInfoNoAuth(dentistInfoId);
  const { activeOfficeId, user } = useActiveOffice();

  const activeOffice = user?.data?.otherOffices?.find(
    (office) => String(office.id) === activeOfficeId,
  );

  const title = officeInfo
    ? `${officeInfo.data.officeName} | Affordable ${TENANT.nameSimple} Membership Plans`
    : activeOffice && staticPages.indexOf('/' + pathname) < 0
    ? `${TENANT.shortName} | ${activeOffice?.officeName}`
    : TENANT.name;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href={TENANT.logos.favIcon} />
      <meta name="description" content={TENANT.meta_description} />
    </Helmet>
  );
};

export default AppHelmet;
