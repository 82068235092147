import { AxiosError } from 'axios';
import {
  activateInOfficeMember,
  checkExistingEmail,
  connectStripePlaidEndpoint,
  getPMSMembersBySearchTerm,
  getPlaidLinkTokenEndpoint,
  reEnrollEndpoint,
  signup,
} from 'new/modules/InternalPatientSignup/endpoints';
import {
  AddMember,
  ConnectStripePlaidRequest,
  ConnectStripePlaidResponse,
  GetPlaidLinkTokenResponse,
  InternalPatientsStore,
  PlanType,
  SignupRequest,
  SignupResponse,
} from 'new/modules/InternalPatientSignup/types';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import create from 'zustand';

export const useInternalPatientsStore = create<InternalPatientsStore>(
  (set) => ({
    members: [] as AddMember[],
    appliedGroupCode: undefined,
    memberAddStep: 'MANUAL',
    reenrollAddress: undefined,
    planType: 'uninsured',
    setReenrollAddress: (reenrollAddress) => set({ reenrollAddress }),
    setMembers: (members) => {
      set({ members });
    },
    setAccountOwner: (accountOwner) =>
      set({ accountOwner: accountOwner || undefined }),
    setGroupCode: (appliedGroupCode) => set({ appliedGroupCode }),
    setMemberAddStep: (memberAddStep) => set({ memberAddStep }),
    setPlanType: (planType: PlanType) => set({ planType }),
    resetPatientStore: () =>
      set({
        members: [],
        accountOwner: undefined,
        appliedGroupCode: undefined,
        reenrollAddress: undefined,
        planType: undefined,
      }),
  }),
);

export type ErrorResponse = {
  meta?: {
    code: number;
  };
  errors?: Record<string, { msg: string }>;
  message?: string;
};

export const useSignup = () =>
  useMutation<SignupResponse, AxiosError<ErrorResponse>, SignupRequest>(
    (params) => signup.endpoint(params).then((res) => res.data),
  );

export const useReEnroll = () => useMutation(reEnrollEndpoint);

export const useCheckEmail = () => useMutation(checkExistingEmail.endpoint);

export const useActivateInOfficeMember = () =>
  useMutation(activateInOfficeMember);

export const useGetPlaidLinkToken = () =>
  useMutation<GetPlaidLinkTokenResponse, unknown, unknown>(() =>
    getPlaidLinkTokenEndpoint.endpoint().then((res) => res.data),
  );

export const useConnectStripePlaid = () =>
  useMutation<ConnectStripePlaidResponse, unknown, ConnectStripePlaidRequest>(
    (params) =>
      connectStripePlaidEndpoint.endpoint(params).then((res) => res.data),
  );

export const usePMSMembersSearch = (
  dentistInfoId: string,
  memberSearch: string,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: 'PMS_MEMBERS_SEARCH',
    queryFn: () =>
      getPMSMembersBySearchTerm
        .endpoint({ dentistInfoId, memberSearch })
        .then((res) => res.data),
    enabled: memberSearch.length >= 2,
  });

  useEffect(() => {
    if (memberSearch.length >= 2) {
      refetch();
    }
  }, [memberSearch, refetch]);

  return {
    data:
      data && Array.isArray(data.members)
        ? data.members.map((data) => {
            return {
              label: `${data.firstName} ${data.lastName}`,
              value: data.chartId,
              data,
            };
          })
        : [],
    isLoading: isLoading || isRefetching,
  };
};
